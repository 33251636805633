<template>
  <!-- <div class="wameed-filter-modal  "> -->
  <b-modal
    id="description-modal"
    class="description-modal"
    v-model="show" 
    title=""
    no-fade
    hide-footer
    scrollable
  >
    <template #modal-header >
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4 class="text-medium-20">{{$t('job_description')}}</h4>
        <b-button variant="gray" size="sm" class="close" @click="show = false">
          <close-icon />
        </b-button>
      </div>
    </template>
    <div v-if="description" v-html="description" >
      <!-- types -->
    
    </div> 
  </b-modal>
</template>

<script>  
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    description:{
      type:String,
      default:null
    }
  }, 
  data: () => ({ 
  }),
  computed: { 
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    
  },
};
</script>
 
