<template>
  <div class="wameed-dashboard-page-content">
    <section class="wameed-dashboard-page-content_body">
      <b-row v-if="getJobDetails" class="no-gutters px-2 my-1">
        <b-col lg="12" md="12" class="px-0 py-3">
          <b-card no-body class="cursor-pointer wameed-card px-0">
            <b-card-body class="position-relative justify-content-between">
              <b-media no-body class="flex-wrap">
                <b-media-aside class="px-4 mx-0 my-2 py-1">
                  <b-avatar variant="gray" rounded size="188">
                    <span class="text-bold-18 text-font-secondary">{{
                      getJobDetails.image
                    }}</span>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="flex-1 px-4 my-2 py-1">
                  <div class="d-flex justify-content-between py-5 my-1">
                    <span class="mx-2 text-medium-16 text-font-sub">{{
                      $t("job_state")
                    }}</span>
                    <b-badge
                      v-if="getJobDetails.state == 0"
                      variant="light-main"
                      pill
                      class="text-medium-11 px-4 border-main job-badge"
                    >
                      {{ $t("active") }}
                    </b-badge>
                    <b-badge
                      v-if="getJobDetails.state == 1"
                      variant="light-danger"
                      pill
                      class="text-medium-11 px-4 border-danger job-badge"
                    >
                      {{ $t("hidden") }}
                    </b-badge>
                  </div>
                  <div class="dropdown-divider" />
                  <div class="d-flex justify-content-between py-5 my-1">
                    <span class="mx-2 text-medium-16 text-font-sub">{{
                      $t("job_name")
                    }}</span>
                    <span class="mx-2 text-medium-16 text-font-main">{{
                      getJobDetails.name
                    }}</span>
                  </div>
                  <div class="dropdown-divider" />
                  <div class="d-flex justify-content-between py-5 my-1">
                    <span class="mx-2 text-medium-16 text-font-sub">{{
                      $t("job_section")
                    }}</span>
                    <span class="mx-2 text-medium-16 text-font-main">{{
                      getJobDetails.section.title
                    }}</span>
                  </div>
                  <div class="dropdown-divider" />
                  <div class="d-flex justify-content-between py-5 my-1">
                    <span class="mx-2 text-medium-16 text-font-sub"
                      >{{ $t("job_type") }}
                    </span>
                    <span
                      v-if="getJobDetails.type == 1"
                      class="mx-2 text-medium-16 text-font-main"
                      >{{ $t("full_time") }}</span
                    >
                    <span
                      v-if="getJobDetails.type == 2"
                      class="mx-2 text-medium-16 text-font-main"
                      >{{ $t("part_time") }}</span
                    >
                    <span
                      v-if="getJobDetails.type == 3"
                      class="mx-2 text-medium-16 text-font-main"
                      >{{ $t("contract") }}</span
                    >
                    <span
                      v-if="getJobDetails.type == 4"
                      class="mx-2 text-medium-16 text-font-main"
                      >{{ $t("freelancer") }}</span
                    >
                  </div>
                  <div class="dropdown-divider" />
                  <div class="d-flex justify-content-between py-5 my-1">
                    <span class="mx-2 text-medium-16 text-font-sub">{{
                      $t("job_place")
                    }}</span>
                    <span class="mx-2 text-medium-16 text-font-main">{{
                      getJobDetails.place.name
                    }}</span>
                  </div>
                  <div class="dropdown-divider" />
                  <div class="d-flex justify-content-between py-5 my-1">
                    <span class="mx-2 text-medium-16 text-font-sub">{{
                      $t("job_salary")
                    }}</span>
                    <span class="mx-2 text-medium-16 text-font-main">{{
                      getJobDetails.salary
                    }}</span>
                  </div>
                  <div class="dropdown-divider" />
                  <div class="d-flex justify-content-between py-5 my-1">
                    <span class="mx-2 text-medium-16 text-font-sub">
                      {{ $t("experience") }}</span
                    >
                    <div class="d-flex flex-wrap">
                      <b-badge
                        v-for="(item, i) in getJobDetails.experience"
                        :key="i"
                        pill
                        variant="light"
                        class="mx-2 px-3 text-medium-16 text-font-secondary"
                      >
                        {{ item.title }}
                      </b-badge>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="d-flex justify-content-between py-5 my-1">
                    <span class="mx-2 text-medium-16 text-font-sub">{{
                      $t("application_end_date")
                    }}</span>
                    <span class="mx-2 text-medium-16 text-font-main">{{
                      getJobDetails.end_date
                    }}</span>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="d-flex justify-content-between pt-5 mt-1">
                    <span class="mx-2 text-medium-16 text-font-sub">{{
                      $t("job_description")
                    }}</span>
                    <a
                      class="mx-2 text-medium-16 text-font-main"
                      @click="showDescription = true"
                      ><u>{{ $t("show_description") }}</u></a
                    >
                    <description-modal
                      :description="getJobDetails.description"
                      :visible="showDescription"
                      @close="showDescription = !showDescription"
                    />
                  </div>
                </b-media-body>
              </b-media>
            </b-card-body>
            <b-card-footer
              class="d-flex flex-wrap justify-content-between px-0 mx-5"
            >
              <div class="d-flex flex-wrap">
                <div class="my-2 py-1">
                  <wameed-btn
                    classes="text-medium-16   text-font-secondary rounded-8 "
                    :title="$t('update_job')"
                    type="button"
                    variant="disable"
                    @submitAction="updateJob()"
                  />
                </div>
                <div class="mx-4 mt-2 pt-1">
                  <wameed-switch
                    name="check-button"
                    @submitAction="
                      updateStateJob($router.currentRoute.params.id)
                    "
                    :title="$t('update_job')"
                    :subTitle="$t('hide_job_details')"
                    sublabelClass="text-medium-16 text-font-secondary mt-4"
                    labelClass=""
                    :checked="getJobDetails.state == 1"
                    classes="text-medium-18 text-font-main"
                  />
                </div>
              </div>
              <div class="my-2 py-1">
                <wameed-btn
                  variant="light-danger"
                  classes="text-medium-16    rounded-8  "
                  :title="$t('delete_job')"
                  type="button"
                  @submitAction="filterOn = !filterOn"
                />
              </div>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
      <div class="my-5">
        <fetch-applicants :isJob="true" />
      </div>
    </section>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import WameedBtn from "@/components/WameedBtn.vue";
import WameedDropdown from "@/components/WameedDropdown.vue";
import WameedAsideModal from "@/components/WameedAsideModal.vue";
import WameedSwitch from "@/components/WameedSwitch.vue";
import { mapActions, mapGetters } from "vuex";
import FetchApplicants from "@/components/pages/applicants/FetchApplicants.vue";
import DescriptionModal from "@/components/pages/setting/jobs/DescriptionModal.vue";

export default {
  components: {
    TextInput,
    WameedBtn,
    WameedDropdown,
    WameedAsideModal,
    WameedSwitch,
    FetchApplicants,
    DescriptionModal,
  },
  data() {
    return {
      showDescription: false, // show description modal
    };
  },
  computed: {
    ...mapGetters({ getJobDetails: "getJobDetails" }),
  },
  created() {
    this.loadJobDetails(this.$router.currentRoute.params.id);
  },
  methods: {
    ...mapActions({
      loadJobDetails: "loadJobDetails",
      updateStateJob: "updateStateJob",
      deleteJob: "deleteJob",
    }),
    updateJob() {
      this.$router.push({
        name: "update-job",
        params: {
          lang: this.$i18n.locale,
          id: this.$router.currentRoute.params.id,
        },
      });
    },
  },
};
</script>
